import React, {useState} from 'react';
import {
    HiOutlineHome,
    HiOutlineHomeModern,
    HiEnvelope,
    HiOutlineCurrencyDollar,
    HiOutlineTrophy,
    HiOutlineUserGroup,
    HiBarsArrowUp,
    HiBarsArrowDown,
  } from "react-icons/hi2";

import ShibaLogo from '../pictures/shibaLogo.jpeg';
import CocktailTokenLogo from '../pictures/cryptobartl_logo.png';

function TokenFactory()
{
    //for collapsing
    const [openNavigationBar, setOPenNavigationBar] = useState(true);
    const toggle_NavigationBar = () => {
        setOPenNavigationBar(!openNavigationBar);
    };



    //for collapsing
  
  const [openTokenFactory, setOPenTokenFactory] = useState(false);
  const [openTokenOptions, setOPenTokenOptions] = useState(false);
  const [openShibConisProject, setOPenShibConisProject] = useState(false);
  const [openCocktailTokenProject, setOPenCocktailTokenProject] = useState(false);

  

  const toggle_TokenFactory = () => {
    setOPenTokenFactory(!openTokenFactory);
  };
  const toggle_TokenOptions = () => {
    setOPenTokenOptions(!openTokenOptions);
  };
  const toggle_ShibConisProject = () => {
    setOPenShibConisProject(!openShibConisProject);
  };
  const toggle_CocktailTokenProject = () => {
    setOPenCocktailTokenProject(!openCocktailTokenProject);
  };


    return (
        <main>
            <div id="divHomepage" className='homepageDesign'>
            
            {openNavigationBar && (
            <div className="toggle">
                <div className="topnav" id="mytopnav">
                        <br/>
                        <ul className='topnav'>
                            <li className='navigation_li'> <a href="/Homepage"> <HiOutlineHome/> Home</a></li>
                            <li className='navigation_li'><a href="/CryptoWorld"> <HiOutlineCurrencyDollar/> Crypto World</a></li>
                            <li className='navigation_li' style={{background: "#3d0863"}}><a  href="/TokenFactory"> <HiOutlineHomeModern/> Token Factory</a></li>  
                            <li className='navigation_li'><a href="/NFTFactory"> <HiOutlineHomeModern/> NFT Factory</a></li>
                            <li className='navigation_li'><a href="/CryGIA_Marketplace"> <HiOutlineUserGroup/> NFT Marketplace-CryGIA</a></li>
                            <li className='navigation_li'><a href="/CryptoCocktailParty"><HiOutlineTrophy/> Cocktail Party</a></li>
                            <li className='navigation_li'><a  href="/ContactUs"><HiEnvelope/> Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            )}
            <button onClick={toggle_NavigationBar} className="factoryTitle"> <HiBarsArrowUp/>
                { 'Collapse NavBar'} 
            </button>
    
            
    <div id="TokenFactoryDIV" >
      <h1 className="factoryTitle"> The Token Factory </h1>

      <div style={{ borderTop: "2px solid #333 ", marginLeft: 20, marginRight: 20 }}></div>
      <div id="internalNav" classname="intpageNavigation">
            <p className='ulMidP'>DefinitionPage: </p>
            <ul className='intpageNavigation'>
                <li className='intpageNavigation'> <a href="#TokenNetworks" className='intpageNavigation'>#DefinitionsPage: Token Networks</a></li>
                <li className='intpageNavigation'> <a href="#TypeOfTokens" className='intpageNavigation'>#DefinitionsPage: Type of Tokens</a></li>
                <li className='intpageNavigation'> <a href="#IdeasTokenUsage" className='intpageNavigation'>#DefinitionsPage: Ideas for Token Usage</a></li>
                <li className='intpageNavigation'> <a href="#TokenProperties" className='intpageNavigation'>#DefinitionsPage: TokenProperties</a></li>   
            </ul>
            <button onClick={toggle_TokenFactory} className="factoryTitle"> <HiBarsArrowDown/>
                    {'Press to fall down the Token Factory - DefinitionsPage'} 
            </button>
            <br/>
            <br/>
            <div style={{ borderTop: "2px solid #333 ", marginLeft: 20, marginRight: 20 }}></div>
            <p className='ulMidP'>Projects: </p>
            <ul> 
                <li className='intpageNavigation'> <a href="#ShibConis" className='intpageNavigation'>#Project0: ShibConis</a></li>
                <li className='intpageNavigation'> <a href="#CocktailToken" className='intpageNavigation'>#Project1: Cocktail Token</a></li>
            </ul>
      </div>
      <div style={{ borderTop: "2px solid #333 ", marginLeft: 20, marginRight: 20 }}></div>

      <div id="DefinitionsPage">
      
      {openTokenFactory && (
              <div className="toggle">
                  <div id="TokenNetworks" className='factoriesDesign'>
                  <h2>Token Networks</h2>
                  <p>Our services are supporting the ERC20 (Ethereum) and the BEP20 (Binance Chain) standards.</p>
                  <p>Following networks are taken into consideration by us:</p>
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Network</th>
                          <th scope="col">Property</th>
                          <th scope="col">Recommanded by us</th>
                        </tr>
                      </thead>
                      <tbody>
                            <tr>
                              <th scope="row">Ethereum</th>
                              <td>most popular, highest TotalValueLocked, highest transaction volume but quite high fees</td>
                              <td>yes</td>
                            </tr>
                            <tr>
                              <th scope="row">Tron</th>
                              <td>Large transaction volume mainly due to stable coins and low fee, but not many tokens use it</td>
                              <td>no</td>
                            </tr>
                            <tr>
                              <th scope="row">Binance Smart Chain</th>
                              <td>Low transaction fees,, sufficient network speed and very high popularity</td>
                              <td>yes</td>
                            </tr>
                            <tr>
                              <th scope="row">Arbitrum One</th>
                              <td>Ethereum Layer 2, low transaction fee, gaining popularity</td>
                              <td>yes</td>
                            </tr>
                            <tr>
                              <th scope="row">Optimism</th>
                              <td>Ethereum Layer 2, allows same as Ethereum, much less in terms of costs</td>
                              <td>yes</td>
                            </tr>
                            <tr>
                              <th scope="row">Avalance AVAX</th>
                              <td>Maximum 4500 transactions per second, low transaction fee</td>
                              <td>no</td>
                            </tr>
                            <tr>
                              <th scope="row">Polygon MATIC</th>
                              <td>Very fast transaction, low cost</td>
                              <td>no</td>
                            </tr>
                          </tbody>
                    </table>
                  <br/>
                  <br/>
                  </div>

                  <div id="TypeOfTokens" className='factoriesDesign'>
                    <h2>Type of tokens</h2>
                    <p>Memecoin: gains value only within publicity. E.g. Dogecoin, ShibaInu</p>
                    <p>Deflationary: supply decreases over time, have a high burn percentage in the smart contract. E.g. GoldenEgg on BSC: 
                      one million tokens, in which 6% fee is charged every transaction. 4% distribution to the holders, 2% is burned.
                    </p>
                    <p>Holder (Divided-paying tokens): awards received for holding the token. The mechanism encourages investors to hold in exchange
                      for receiving dividents from token transactions (for every buy and sell transaction). 
                      "Just buy tokens, keep them in your wallet,
                      don't sell them, get rewards every 60 minutes. That's it. No additional action required."
                    </p>
                    <p>Play2Earn: e.g. Axie Infinity - a gaming concept in which the game provides its players which a chance to earn cards, items and more, which can later ve converted into cryptocurrencies
                      or FIAT. Mindset to be established in such games: not the "play to earn", but to "play and earn"!
                    </p>
                  </div>

                  <br/>
                  <div id="IdeasTokenUsage" className='factoriesDesign'>
                      <h2>Ideas for token usage</h2>
                      <p>Utility tokens. Tokens with a real use are utility tokens. The token is designed to perform certain actions within the blockchain of 
                        a specific project. They can help create an internal economy. They are commonly sold in the form of fundraising at the project formation.
                        Example: Binance Coin (BNB) is mainly used to pay for trading fees and transactions on the Binance Exchange. Binance encourages to use BNB instead of 
                        stablecoins or orther crypto by offering discounts.
                        Utiliy tokens do not generate by default any return on the investment.
                      </p>
                      <p>Security token. Is a financial instrument that has value and can be traded. It represents a stake in some external company or asset.
                        They can be issued by entities such as companies or governments and serve the same purpose as their counterparts (e.g. stocks, bonds, etc).
                      </p>
                      <p>Exchange token. These are tokens created and issued by exchanges, they provide holders with certain benefits, such as
                        discounts on crypto trading. Example: BNB for Binance, CNB for Coinsbit, HT for Huobi.
                        Having exchange tokens also often entitles one to take part in free token drops of new projects on exchanges.
                      </p>
                  </div>
                  <br/>
                  <br/>

                  <div id="TokenProperties" className='factoriesDesign'>
                      <h2>Configurations which must be implemented for the tokens</h2>
                      <ul className="requestDesign">
                          <li>Token name</li>
                          <li>Symbol: unique combination of letters assigned to a token/coin/stock</li>
                          <li>Total supply: sum of circulating supply and tokens that are locked into a smart contract</li>
                      </ul>
                      <br/>

                      <h3>Optional configurations of the tokens:</h3>
                      <ul className="requestDesign">
                          <li>Maximum supply (optional): the upper limit on the number of tokens that will exist</li>
                          <li>Max Wallet (optional): limit the maximum numbers of tokens a single address can hold</li>
                          <li>Max Tx (optional): limit the maximum number of tokens that can flow into a single transaction</li>
                          <li>Air Drops (optional): pay holders a portion of the fees collected, rewarding them for holding the token in their wallet</li>
                          <li>Fees (optional): </li>
                          <ul>
                              <li> Buy/Sell fee: for buying or selling the token</li>
                              <li> Early sell fee: penalty for early selling</li>
                              <li> Reflection fee: the re-distribution fee</li>
                              <li> Dev fee: for development</li>
                              <li> Burn fee: a fee that is burned</li>
                              <li> Marketing fee: for marketing purposes</li>
                              <li>Charity Fee: transfer funds from each fee, to a designated address</li>
                              <li> Liquidity fee: a fee for which funds go into a liquidity pool</li>
                          </ul>
                          <li>Burning (optional): like burning cash or artwork, virtually. Can increase the price.</li>
                          <li>Mintability (optional): the contract creator can mint more tokens, increasing the total supply</li>
                          <li>Blacklist (optional): prevent specific addresses from participating in certain token-related activities</li>
                          <li>Whitelist (optional): receive exclusive or priority access - e.g. for pre-sales</li>
                      </ul>
                  </div>

                  <br/>
                  <br/>
                  <p style={{fontStyle:'italic', fontSize:12}}>Main source of information:   Boron Piotr. Your own token. 2023 </p>
                  <br/>
                  <br/>
                  <button onClick={toggle_TokenFactory} className="factoryTitle"> <HiBarsArrowUp/>
                    {'Press hide the Token Factory - DefinitionsPage'} 
                  </button>

              </div>
      )}
      </div>
      
      <br/>
      <br/>
      <h1>Get inspired by our Token projects!</h1>
      <br/>

      <div id="ShibConis" className="factoriesDesign">
        <h1>PROJECT 0: ShibConis - The MemeCoin Token</h1>

        <img src={ShibaLogo} alt="ShibConis image" height = {200} width = {200} />
        <br/>
        <br/>
        <a href="https://bscscan.com/address/0xDBA019Fdb2438D98eF40c63dB6305ca7748aEd70#code">Check it on the BSC (Binance Smart Chain)!</a>
        <br/>
        <button onClick={toggle_ShibConisProject} className="factoryTitle"> <HiBarsArrowDown/>
            {'Press to show or hide the ShibConis Project information - The MemeCoin'} 
        </button>


        <br/>
            <br/>
        {openShibConisProject && (
        <div className="projectListDesign">
            
            <p className="homepageDesign">Contract address: 0xDBA019Fdb2438D98eF40c63dB6305ca7748aEd70</p>
            <p>This token is just amaizing. It is going to be a real success. Just checkout the crypto asset description!</p>
              <h4>## What Is The ShibConis token?</h4>
              <p>The ShibConis token is acting as a Memecoin, which is going to be part of the major upcoming project “Crypto Cocktail Party” and the CryptoBartl CryGIA NFT Marketplace (still under construction).
              The Crypto Cocktail Party project will consist of a mobile App, which is going to help you prepare your cocktail based on the ingredients you have at home - also planning a cocktail party will be a feature. For giving feedback to the App and sharing reviewed recipes, rewards will be given to the users - here is where the ShibConis come in place!</p> 
              <p>This will not only increase the interest in the app, but is also expected to raise a lot of sympahty.
              The popularity the token is gaining from the upcoming projects is going to be very beneficial for the owners of the token.</p>

              <p>ShibConis is the utility token that is going to be used for multiple purposes:
              <ul>
                <li>Acts as reward for various activities performed in the Cocktail Party App</li>
                <li>Can be considered as investment asset</li>
                <li>Acts also as reward for posting NFTs on the CryGIA NFT Marketplace</li>
              </ul>

              <h4>##ShibConis tokens include protective mechanisms, to increase confidence of the investors/buyers: </h4>
              <ul>
                <li>Whitelist and Blacklist is implemented</li>
                <li>MaxTx mechanism is set</li>
                <li>MaxWallet is also in place, to avoid whale’s attacks</li>
              </ul>
              </p>
              <h4>## How Many ShibConis Coins Are There in Circulation?</h4>
              <p>The ShibConis was launched on April 03, 2024 with 1 billion tokens created at genesis.  There is a fee of 5% integrated for the transactions, out of which 2% is going as reward to the developer wallet.
              In case of decision to burn the tokens, a fee of 10% is put in place. </p>

              <h4>### Who Are the Founders of ShibConis?</h4>
              <p>ShibConis is the brainchild of CryptoBartl, who has extensive experience in programming and management. As the proud owners of two Shiba Inus, who on their own brough more Shiba Inus into the world, this token is created to live up to their grace. 
              Rewards will be given in the form of the ShibConis. </p>

              <p>The ShibConis will not die as a Hype only. As owner we have the possibility to mint further coins, to assure it will not disappear from the market. 
              We can also increase the usage scenarios of the token, for different upcoming projects – as it will be easily accessible and due to our original physical location, a great advantage for various investors and start-ups can be provided.</p>
            
        </div>
        )}
      </div>
              

      <br/>
            <div id="CocktailToken" className="factoriesDesign">
            <h1>PROJECT 1: Cocktail Token </h1>
            
            <img src={CocktailTokenLogo} alt="Cocktail token image" height = {200} width = {200} />
            <br/>
            <br/>
            <a href="https://bscscan.com/address/0xA825094bD6053FeF815EbE8ED7Ac9d5539cE67B1#code">Check it on the BSC (Binance Smart Chain)!</a>
            <br/>
            <button onClick={toggle_CocktailTokenProject} className="factoryTitle">
              {'Press to show or hide the Cocktail Token Project'} 
              </button>
              {openCocktailTokenProject && (
                <div className="projectListDesign">
                  <p>Planned launched date (if approved by CoinMarketCap): 15th May 2024.</p>

                  <h4>## What Is CocktailToken (CCK)?</h4>
                  <p>CocktailToken is crypto token / smart contract created on the Binance Smart Chain network.
                    CocktailToken is one of a until now two cryptocurrency tokens administered by CryptoBartl, a startup company making its promising way in the crypto world.
                    CocktailToken aims to facilitate increased liquidity and provide cryptocurrency traders and general users with an asset, encouraging the youth to consider investing in cryptocurrencies. The price should be easily accessible and correct advertising will also increase the interest. 
                    An upcoming plan is to arrange getting the CocktailToken popular in the nightlife scene, also considering it as potential reward method (e.g., tip to the bartender).</p>
                  <h4>### Who Are the Founders of CocktailToken?</h4>
                  <p>CocktailToken is a token launched by the company CryptoBartl, whosefounder and CEO is Elena Bartl. Together with her husband and further family members, the CocktailToken brainchild was founded.
                    Gaining extended experience from various top industries and keeping up with past connections, the success of the company is very likey.</p>
                  <h4>#### What Makes CocktailToken Unique?</h4>
                  <p>CocktailToken main aim is to increase the interest in crypto investments.  
                    By making it popular in the nightlife scene, the youth will become more aware of the power of crypto. Small investments, done just by side (e.g., when ordering a real-life cocktail, you should also buy yourself a crypto cocktail token), will make the difference.
                    Future plans involving the CocktailToken are also in place, and the Crypto Cocktail Party application / project is already in progress. The main idea is to give the CocktailToken two additional usages,
                    <ul>
                      <li>Handle the CocktailToken as Reward (in the application itself, but also in the real life)</li>
                      <li>Handle the CocktailToken as Exchange token, receiving a remarkable discount on the provided services</li>
                    </ul>
                  </p>
                  <h4>#### How Many CocktailToken (CCK) Coins Are There in Circulation?</h4>
                  <p>There are one billion CCK in circulation as of April 2024. Given its status, the supply is uncapped, and will continue to expand according to demand. In case of having the necessity to mint further tokens, the holders will be informed ahead, and the top 5 wallet amount holders will be part of the decision makers. 
                      Same applies to the burning process.
                  </p>
                  <h4>### How Is the CocktailToken Secured?</h4>
                  <p>The security of the CocktailToken is assured by following the standards of ERC20 and BEC20.
                    The CocktailToken’s smart contract has different methods to keep the token safe from fraud or price manipulators. There is a method implemented for MaxWallet and MaxTx, to avoid abrupt price fluctuations. Suspicious addresses can be in the end locked in a Blacklist. 
                  </p>

            </div>
            )}
        </div>
        </div>

        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
        <p style={{textAlign: "center"}}>Copyright © 2024 CryptoBartl</p>
            
      </div>
      </main>
    )

}

export default TokenFactory;
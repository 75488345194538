import './App.css'
import React from 'react';
import {BrowserRouter, useNavigation, Navigate, Route, Routes} from "react-router-dom"

import Homepage from "./pages/Homepage";
import CryptoWorld from "./pages/CryptoWorld";
import TokenFactory from "./pages/TokenFactory";
import NFTFactory from "./pages/NFTFactory";
import CryGIA_Marketplace from "./pages/CryGIA_Marketplace";
import CryptoCocktailParty from "./pages/CryptoCocktailParty";
import ContactUs from "./pages/ContactUs";
import PageNotFound from "./pages/PageNotFound";

//useEffect hook tells our app to do something after rendering
function App()
{
  
  return (

    <BrowserRouter>
      <Routes>
        <Route index element ={<Navigate replace to ="Homepage"/>} />
        <Route path="Homepage" element ={<Homepage />} />
        <Route path="CryptoWorld" element ={<CryptoWorld />} />
        <Route path="TokenFactory" element ={<TokenFactory />} />
        <Route path="NFTFactory" element ={<NFTFactory />} />
        <Route path="CryGIA_Marketplace" element ={<CryGIA_Marketplace />} />
        <Route path="CryptoCocktailParty" element ={<CryptoCocktailParty />} />
        <Route path="ContactUs" element ={<ContactUs />} />
        <Route path="*" element ={<PageNotFound />} />
      </Routes>
    </BrowserRouter>

  ) 
  
}

export default App;

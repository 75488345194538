import React, {useState} from 'react';
import {
    HiOutlineHome,
    HiOutlineHomeModern,
    HiEnvelope,
    HiOutlineCurrencyDollar,
    HiOutlineTrophy,
    HiOutlineUserGroup,
    HiBarsArrowUp,
  } from "react-icons/hi2";

function ContactUs()
{
    //for collapsing
    const [openNavigationBar, setOPenNavigationBar] = useState(true);
    const toggle_NavigationBar = () => {
        setOPenNavigationBar(!openNavigationBar);
    };

    return (
        <main>
            <div id="divHomepage" className='homepageDesign'>
            
            {openNavigationBar && (
            <div className="toggle">
                <div className="topnav" id="mytopnav">
                        <br/>
                        <ul className='topnav'>
                            <li className='navigation_li'> <a href="/Homepage"> <HiOutlineHome/> Home</a></li>
                            <li className='navigation_li'><a href="/CryptoWorld"> <HiOutlineCurrencyDollar/> Crypto World</a></li>
                            <li className='navigation_li'><a  href="/TokenFactory"> <HiOutlineHomeModern/> Token Factory</a></li>  
                            <li className='navigation_li'><a href="/NFTFactory"> <HiOutlineHomeModern/> NFT Factory</a></li>
                            <li className='navigation_li'><a href="/CryGIA_Marketplace"> <HiOutlineUserGroup/> NFT Marketplace-CryGIA</a></li>
                            <li className='navigation_li'><a href="/CryptoCocktailParty"><HiOutlineTrophy/> Cocktail Party</a></li>
                            <li className='navigation_li'><a  href="/ContactUs"><HiEnvelope/> Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            )}
            <button onClick={toggle_NavigationBar} className="factoryTitle"> <HiBarsArrowUp/>
                { 'Collapse NavBar'} 
            </button>
            
                
                <div>
                    <p></p>
                    <p></p>
                    <h2 style={{textAlign:"center"}}> Get connected with us on social networks:</h2>
                    <br/>
                    <br/>
                    <a href="https://twitter.com/CryptoBartl/">CryptoBartl - on X (Twitter)</a>
                    <p></p>
                    <a href="https://t.me/cryptobartl">CryptoBartl - on Telegram</a>
                    <br/>
                    <br/>
                    <br/>
                    <a>Email : emb@cryptobartl.com</a>
                    <p></p>
                    
                  <br/>
                  <p> </p>
                </div>

                <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
                <p style={{textAlign: "center"}}>Copyright © 2024 CryptoBartl</p>

            </div>
        </main>
    )
}

export default ContactUs;
import React, {useState} from 'react';
import {
    HiOutlineHome,
    HiOutlineHomeModern,
    HiEnvelope,
    HiOutlineCurrencyDollar,
    HiOutlineTrophy,
    HiOutlineUserGroup,
    HiBarsArrowUp,
  } from "react-icons/hi2";

  
  import { useMoveBack } from "../hooks/useMoveBack";


function CryptoCocktailParty()
{
    //for collapsing
    const [openNavigationBar, setOPenNavigationBar] = useState(true);
    const toggle_NavigationBar = () => {
        setOPenNavigationBar(!openNavigationBar);
    };

    const moveBack = useMoveBack();

    return (
        <main >
            <div id="divHomepage" className='comingSoonDesign'>
            {openNavigationBar && (
            <div className="toggle">
                <div className="topnav" id="mytopnav">
                        <br/>
                        <ul className='topnav'>
                            <li className='navigation_li'> <a href="/Homepage"> <HiOutlineHome/> Home</a></li>
                            <li className='navigation_li'><a href="/CryptoWorld"> <HiOutlineCurrencyDollar/> Crypto World</a></li>
                            <li className='navigation_li'><a  href="/TokenFactory"> <HiOutlineHomeModern/> Token Factory</a></li>  
                            <li className='navigation_li'><a href="/NFTFactory"> <HiOutlineHomeModern/> NFT Factory</a></li>
                            <li className='navigation_li'><a href="/CryGIA_Marketplace"> <HiOutlineUserGroup/> NFT Marketplace-CryGIA</a></li>
                            <li className='navigation_li'   style={{background: "#3d0863"}}><a href="/CryptoCocktailParty"><HiOutlineTrophy/> Cocktail Party</a></li>
                            <li className='navigation_li'><a  href="/ContactUs"><HiEnvelope/> Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            )}
            <button onClick={toggle_NavigationBar} className="factoryTitle"> <HiBarsArrowUp/>
                { 'Collapse NavBar'} 
            </button>
                
            <div className='ComingSoonDesign'>  
                <div  style={{textAlign: "center"}}>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                    <h1 style={{textAlign: "center"}}> The Crypto Cocktail Party is coming soon!</h1>
                    <br/>
                    <br/>
                    <h3 style={{textAlign: "center"}}>The Crypto Cocktail Party Project is expected to be launched end 2024. Follow us to get the latest updates!</h3>
                </div>

                <br/>
                <br/>

                <button onClick={moveBack} size="large">
                    &larr; Go back
                </button>

                <br/>
                <br/>
                <br/>

                <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
                <p style={{textAlign: "center"}}>Copyright © 2024 CryptoBartl</p>

            </div>
            </div>  
        </main>
    )
}

export default CryptoCocktailParty;
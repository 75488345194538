import '../TableDesign.css'
import '../App.css'
import React, {useState} from 'react';
import {useEffect} from 'react';

import {
    HiOutlineHome,
    HiOutlineHomeModern,
    HiEnvelope,
    HiOutlineCurrencyDollar,
    HiOutlineTrophy,
    HiOutlineUserGroup,
    HiBarsArrowUp,
    HiArrowRightOnRectangle,
  } from "react-icons/hi2";

import ShibaLogo from '../pictures/shibaLogo.jpeg';
import CocktailTokenLogo from '../pictures/cryptobartl_logo.png';


import styled from 'styled-components';

const StyledDiv = styled.div`
margin: auto;
padding: 15px;
background-color: #333;
opacity: 0.95;
text-align: center;
width: 70%;
border-radius: 20px;
outline: none;
border: transparent;
color: white;
font-family: Verdana, Geneva, Tahoma, sans-serif;
`;
const StyledHeader = styled.h1`
opacity: 1;
color: #b86fec;
text-align: center;
`;


//useEffect hook tells our app to do something after rendering
function Homepage()
{
  
  //for collapsing
  const [openNavigationBar, setOPenNavigationBar] = useState(true);
  const [openNFTFactory, setOPenNFTFactory] = useState(false);
  const [openUsefulLinks, setOPenUsefulLinks] = useState(false);

  const toggle_NavigationBar = () => {
    setOPenNavigationBar(!openNavigationBar);
  };
  const toggle_NFTFactory = () => {
    setOPenNFTFactory(!openNFTFactory);
  };
  const toggle_UsefulLinks = () => {
    setOPenUsefulLinks(!openUsefulLinks);
  };


  function moreInfo()
  {
    window.confirm("Website is still under construction. Contact us at: emb@cryptobartl.com");
  }

  //in return is where we are rendering our data
  return (

    <main>
      <div id="divHomepage" className='homepageDesign'>       
        {openNavigationBar && (
            <div className="toggle">
                <div className="topnav" id="mytopnav">
                        <br/>
                        <ul className='topnav'>
                            <li className='navigation_li' style={{background: "#3d0863"}}> <a href="/Homepage"> <HiOutlineHome/> Home</a></li>
                            <li className='navigation_li'><a href="/CryptoWorld"> <HiOutlineCurrencyDollar/> Crypto World</a></li>
                            <li className='navigation_li'><a  href="/TokenFactory"> <HiOutlineHomeModern/> Token Factory</a></li>  
                            <li className='navigation_li'><a href="/NFTFactory"> <HiOutlineHomeModern/> NFT Factory</a></li>
                            <li className='navigation_li'><a href="/CryGIA_Marketplace"> <HiOutlineUserGroup/> NFT Marketplace-CryGIA</a></li>
                            <li className='navigation_li'><a href="/CryptoCocktailParty"><HiOutlineTrophy/> Cocktail Party</a></li>
                            <li className='navigation_li'><a  href="/ContactUs"><HiEnvelope/> Contact Us</a></li> 
                        </ul>
                  </div>
              </div>
        )}
      <button onClick={toggle_NavigationBar} className="factoryTitle"> <HiBarsArrowUp/>
        { 'Collapse NavBar'} 
      </button>


      <h1 style = {{fontSize: '52px', textAlign:'center'}}> Welcome to CryptoBARtl ! </h1>
      <p>We wish you a warm welcome to our CryptoBartl homepage!</p>
      <p>If you keep following us and this webpage, you'll get a great knowledge on cryptocurrency.</p>
      <p>We offer services you and your company might find Life-Changing.</p>
      <br/>
      <br/>
      <p>If you are interested in tokens, you should visit our 
        <a  href="/TokenFactory"> #Token Factory</a> </p>
      <p>If you are interested in NFTs, take a ride to our 
       <a  href="/NFTFactory"> #NFT Factory</a> </p>
      <p>Either way, do not forget to visit and get inspired by 
      <a  href="/CryGIA_Marketplace"> #CryGIA</a>
        , the NFT Gallery and Marketplace!</p>
      <br/>
      <p>We are also proud to present the first two projects, the
        <a  href="/TokenFactory#ShibConis"> ShibConis </a>
        and 
        <a  href="/TokenFactory#CocktailToken"> Cocktail Token  </a>
        . These will be part of the amaizing 
        <a  href="/CryptoCocktailParty"> Crypto Cocktail Party </a>
        big Project!</p>
      <br/>
      <br/>
      <br/>

      <StyledDiv id="HomepageCryptoWorld">
        <StyledHeader> Crypto World </StyledHeader>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
        <p>If you are a newbie to crypto, or would like to recap your crypto knowledge, go ahead checking out our Crypto World. </p>
        <p>Basic information regarding crypto will be presented to you - for example: </p>
        <p>What is a blockchain?</p>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
       
        <a href="/CryptoWorld"> <button> {'Go to the CryptoWorld   '} <HiArrowRightOnRectangle/> </button> </a>
      </StyledDiv>

      <br/>
      <br/>

      <StyledDiv id="HomepageCryptoWorld">
        <StyledHeader> Token Factory </StyledHeader>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
        <p>Are you also having the dream of having your own Crypto Token?</p>
        <p>First you have to understand all the options we offer in the creation of the smartcontract.</p>
        <br/>
        <p>This service will be provided to you soon.</p>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
       
        <a href="/TokenFactory"> <button> {'Go to the Token Factory   '} <HiArrowRightOnRectangle/> </button> </a>
      </StyledDiv>
      
      <br/>
      <br/>

      <StyledDiv id="HomepageCryptoWorld">
        <StyledHeader> NFT Factory </StyledHeader>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
        <p>Get your own personal NFT on the blockchain!</p>
        <p>First you have to understand all the options we offer in the creation of the smartcontract.</p>
        <p>Special discounts on displying your NFT on our upcoming marketplace will be granted.</p>
        <br/>
        <p>This service will be provided to you soon.</p>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
       
        <a href="/NFTFactory"> <button> {'Go to the NFT Factory   '} <HiArrowRightOnRectangle/> </button> </a>
      </StyledDiv>
      
      <br/>
      <br/>
      
      <StyledDiv id="HomepageCryptoWorld">
        <StyledHeader> PROJECT 0: Shibconis Memecoin </StyledHeader>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
        <br/>
        <br/>
        <img src={ShibaLogo} alt="ShibConis image" height = {200} width = {200} />
        <br/>
        <br/>
        <a href="https://bscscan.com/address/0xDBA019Fdb2438D98eF40c63dB6305ca7748aEd70#code">Check it on the BSC (Binance Smart Chain)!</a>
        <p>Coin Market Cap ID: 889080 [7WG911-J6Z79]</p>  
        <p>This token is just amaizing. It is going to be a real success. Just checkout the crypto asset description!</p>
        <br/>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
       
        <a href="/TokenFactory/#ShibConis"> <button> {'Go to ShibConis   '} <HiArrowRightOnRectangle/> </button> </a>
      </StyledDiv>
      
      <br/>
      <br/>
      
      <StyledDiv id="HomepageCryptoWorld">
        <StyledHeader> PROJECT 1: CocktailToken </StyledHeader>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
        <br/>
        <br/>
        <img src={CocktailTokenLogo} alt="Cocktail token image" height = {200} width = {200} />
        <br/>
        <br/>
        <a href="https://bscscan.com/address/0x038E4Cb215731B927Af31c320360DC37bd14b2d7#code">Check it on the BSC (Binance Smart Chain)! - UPDATED CONTRACT!</a>
        <p>Coin Market Cap ID: -</p>
        <p>CoinGecko ID: -</p>
        
        <p>Coming soon!</p>
        <br/>
        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
       
        <a href="/TokenFactory/#CocktailToken"> <button> {'Go to Cocktail Token   '} <HiArrowRightOnRectangle/> </button> </a>
      </StyledDiv>

      <br/>
      <br/>
      <br/>
      <br/>

      
        <div id="EndPage">
        <button onClick={toggle_UsefulLinks}>
                {'USEFUL LINKS'} 
                </button>
                {openUsefulLinks && (
                  <div className="toggle">
                    <br/>
                    <br/>
                    <a href="https://bscscan.com/address/0xDBA019Fdb2438D98eF40c63dB6305ca7748aEd70#code">ShibConis on Binance (BSC)</a>
                    <br/>
                    <a href="https://bscscan.com/address/0xA825094bD6053FeF815EbE8ED7Ac9d5539cE67B1#code">Cocktail Token on Binance (BSC)</a>
                    <p> </p>
                  </div>
                )}

        <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
        <p style={{textAlign: "center"}}>Copyright © 2024 CryptoBartl</p>
        </div>
      

    </div>
  </main>




  ) 
  
}

export default Homepage;
